import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

export default function Template() {
  return (
    <>
      <Helmet>
        <title>Page (Template) Title</title>
        <Link to="/static/favicon.png" rel="shortcut icon" />
      </Helmet>
      <header></header>
      <main>
        <div>Hello world!</div>
      </main>
    </>
  )
}